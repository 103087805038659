// this is user/get to avoid a require cycle between teams and users
import { useProfile } from './get'
import { isManagerOfUser, isNotEmpty, type TeamId, type UserData } from '@terros/common'

type ManagerState = {
  isLoading: boolean
  isManager: boolean
  ofTeam: (teamId: TeamId) => boolean
  ofUser: (user: UserData) => boolean
  error?: string
}

export function useIsManager(): ManagerState {
  const userState = useProfile()

  const teamsIManage: TeamId[] = userState.user?.teams?.manages ?? []

  return {
    isLoading: userState.isLoading,
    error: userState.error,
    isManager: isNotEmpty(userState.user?.teams?.manages),
    ofTeam: (id) => userState.isAdmin || teamsIManage.includes(id),
    ofUser: (employee: UserData) => {
      if (userState.isAdmin) return true
      return isManagerOfUser(teamsIManage, employee.teams)
    },
  }
}
